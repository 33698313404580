import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

type RemoveStudentChoiceProps = {
  onDelete: (id: string) => Promise<void>;
};

const RemoveStudentChoice: React.FC<RemoveStudentChoiceProps> = ({ onDelete }) => {
  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setResponseMessage(null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmDelete = async (crsid: string) => {
    setLoading(true);
    try {
      await onDelete(crsid);
      setResponseMessage('Student choices deleted successfully.');
    } catch (error) {
      setResponseMessage('Failed to delete student choices.');
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <>
      <Button variant="contained" color="error" onClick={handleOpen}>
        Delete
      </Button>
      <Dialog open={open} onClose={handleClose} aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {responseMessage
              ? responseMessage
              : "Do you really want to delete this student's data? This action cannot be undone."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {responseMessage ? (
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          ) : (
            <>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={() => handleConfirmDelete('crsid')} disabled={loading}>
                Confirm
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(RemoveStudentChoice);
