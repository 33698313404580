import React, { useMemo } from 'react';
import {
  DataGrid,
  getGridStringOperators,
  GridColDef,
  GridRenderCellParams
} from '@mui/x-data-grid';
import { StudentChoicesResponse } from '../api';
import { Box } from '@mui/material';
import RemoveStudentChoice from './RemoveStudentChoice';
import { useRouteLoaderData } from 'react-router-dom';
import { User } from '../App';

type StudentChoicesTableProps = {
  students: StudentChoicesResponse[];
  loading: boolean;
  onDelete: (id: string) => Promise<void>;
};

const RenderSubjects = (props: GridRenderCellParams<any, StudentChoicesResponse['subjects']>) => (
  <div className="vertical-container" style={{ padding: '5px 0' }}>
    {props.value?.map((subject) => (
      <Box sx={{ m: [0, 0.25] }} key={subject.id}>
        {subject.name}
      </Box>
    )) ?? 'No subjects'}
  </div>
);

/**
 * Custom DataGrid operators for the Subjects column. These operators are based of the default
 * string operators overriding the value to be a concatenation of the subjects names.
 *
 * This is required as default operators cannot handle arrays of objects.
 */
const getSubjectsOperators = () => {
  const baseOperators = getGridStringOperators();

  const subjectOperators = baseOperators.map((op) => {
    const existingGetApplyFilterFn = op.getApplyFilterFn;

    op.getApplyFilterFn = (...args) => {
      const filterFn = existingGetApplyFilterFn(...args);

      if (filterFn === null) return null;

      return ({ value, ...rest }) => {
        // @ts-ignore
        let subjects: StudentChoicesResponse['subjects'] = value;
        value = subjects.map((subject: any) => subject.name).join(', ');
        return filterFn({ value, ...rest });
      };
    };

    return op;
  });

  return subjectOperators;
};

const StudentChoicesTable: React.FC<StudentChoicesTableProps> = ({
  students,
  loading,
  onDelete
}) => {
  const user = useRouteLoaderData('authenticated') as User | undefined;

  const displayTableActions = useMemo(() => {
    const userPermissions = new Set(user?.permissions);
    return userPermissions.has('student.delete_studentchoice');
  }, [user]);

  const columns = useMemo(() => {
    const columns: GridColDef[] = [
      { field: 'crsid', headerName: 'CRSid', width: 100 },
      { field: 'name', headerName: 'Name', width: 150 },
      { field: 'collegeInstitution', headerName: 'College Institution', width: 250 },
      {
        field: 'subjects',
        headerName: 'Subjects',
        minWidth: 300,
        renderCell: RenderSubjects,
        filterOperators: getSubjectsOperators()
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        width: 170,
        renderCell: (c) => (c.value ? new Date(c.value).toLocaleString() : null)
      }
    ];

    if (displayTableActions)
      columns.push({
        field: 'actions',
        headerName: 'Actions',
        minWidth: 150,
        renderCell: (params) => <RemoveStudentChoice onDelete={() => onDelete(params.row.crsid)} />
      });

    return columns;
  }, [displayTableActions, onDelete]);

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={students}
        columns={columns}
        getRowId={(row: StudentChoicesResponse) => row.crsid}
        getRowHeight={() => 'auto'}
        loading={loading}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdAt', sort: 'desc' }]
          }
        }}
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default StudentChoicesTable;
