import React, { useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Typography, Stack } from '@mui/material';
import { selectedSubjectsStore } from './store';
import { apiFetch } from '../api';

interface SubjectGroup {
  numberToSelect: number;
  subjects: Subject[];
}

interface Subject {
  id: string;
  name: string;
  code: string;
}

const SubjectSelection = () => {
  const [subjectGroups, setSubjectGroups] = useState<SubjectGroup[]>([]);
  const { selectedSubjects } = selectedSubjectsStore();

  const handleSubjectSelection = (subjectCode: string, groupIndex: number) => {
    selectedSubjectsStore.setState((state) => {
      const isSelected = state.selectedSubjects.includes(subjectCode);
      if (isSelected) {
        return { selectedSubjects: state.selectedSubjects.filter((s) => s !== subjectCode) };
      } else {
        return { selectedSubjects: [...state.selectedSubjects, subjectCode] };
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiFetch(`/api/v1alpha1/subjectgroups/`, {
        method: 'GET'
      });
      if (response.ok) {
        const data = await response.json();
        setSubjectGroups(data);
      } else {
        console.error('Failed to fetch data');
      }
    };

    fetchData();
  }, []);

  const isCheckboxDisabled = (subjectCode: string, groupIndex: number) => {
    const group = subjectGroups[groupIndex];
    if (!group) return true;
    const selectedInGroup = group.subjects.filter((subject) =>
      selectedSubjects.includes(subject.code)
    ).length;
    const isSubjectSelected = selectedSubjects.includes(subjectCode);
    return selectedInGroup >= group.numberToSelect && !isSubjectSelected;
  };

  return (
    <Box>
      <Stack direction="row" spacing={2}>
        {subjectGroups.map((subjectGroup, index) => (
          <div style={{ border: '3px solid', padding: '10px' }} key={index}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Choose {subjectGroup.numberToSelect} subject
              {subjectGroup.numberToSelect !== 1 ? 's' : ''} from:
            </Typography>
            {subjectGroup.subjects.map((subject) => (
              <div key={subject.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedSubjects.includes(subject.code)}
                      onChange={() => handleSubjectSelection(subject.code, index)}
                      disabled={isCheckboxDisabled(subject.code, index)}
                    />
                  }
                  label={subject.name}
                />
              </div>
            ))}
          </div>
        ))}
      </Stack>
    </Box>
  );
};
export default SubjectSelection;
