import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

const Page404 = () => {
  return (
    <Stack spacing={2} sx={{ alignItems: 'flex-start', width: '100%' }}>
      <h2>404 - Page not found</h2>
      <p>
        The page you're trying to reach was not found, or you do not have permissions to view it.
      </p>
      <Link to="/">Return to home</Link>
    </Stack>
  );
};

export default Page404;
