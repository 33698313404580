import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLoaderData, useRouteLoaderData } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Icon,
  Stack,
  Tab,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import {
  AddCircle,
  ScienceOutlined,
  Cancel,
  CheckCircle,
  Error as ErrorIcon,
  HourglassDisabled,
  PieChart
} from '@mui/icons-material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import { BatchDetailResponse, JobDetailResponse, SubjectsResponse, get, patch } from '../api';
import AllocationMetric from './AllocationMetric';
import AllocationTable from './AllocationTable';
import AllocatorUIModal, { modalStore, ModalTypes } from '../AllocatorUIModal';
import IncompatibleChoicesTable from './IncompatibleChoicesTable';
import PracticalsTable from './PracticalsTable';
import PublishConfirmationModal from './PublishConfirmationModal';

const TabPanelWithoutPadding = styled(TabPanel)(() => ({
  padding: 0
}));

type TabValues = 'allocated' | 'incompatible' | 'practicals';

const JobById: React.FC = () => {
  const theme = useTheme();

  const { setModal } = modalStore();

  const navigate = useNavigate();
  const { jobId } = useParams<{ jobId: string }>();
  const jobDetail = useLoaderData() as JobDetailResponse;
  const batch = useRouteLoaderData('batchRoot') as BatchDetailResponse;
  const [loadingZip, setLoadingZip] = useState(false);
  const [tab, setTab] = useState<TabValues>('allocated');
  const [subjectsResponse, setSubjectsResponse] = useState<SubjectsResponse | null>(null);
  const [loadingSubjects, setLoadingSubject] = useState<boolean>(false);
  const [publishConfirmationModalOpen, setPublishConfirmationModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchSubjects = () => {
      setSubjectsResponse(null);
      setLoadingSubject(true);
      get<SubjectsResponse>(`/api/v1alpha1/jobs/${jobId}/subjects/`)
        .then(setSubjectsResponse)
        .catch((error) => {
          setModal(
            'An error occurred looking up batches: ' + (error as Error).toString(),
            ModalTypes.ALERT
          );
        })
        .finally(() => setLoadingSubject(false));
    };

    fetchSubjects();
  }, [jobId, setModal]);

  const downloadZip = () => {
    setLoadingZip(true);
    fetch(`/api/v1alpha1/jobs/${jobId}/export/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Server responded with ${response.status}: ${response.statusText}`);
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `job-results-${jobId}.zip`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading the Zip file:', error);
        alert(
          "Couldn't download the Zip file. The file might not exist or there was an error processing your request."
        );
      })
      .finally(() => {
        setLoadingZip(false);
      });
  };

  const handlePublishConfirmation = () => {
    patch(`/api/v1alpha1/jobs/${jobId}/publish/`)
      .catch((error) => {
        setModal('An error publishing job: ' + (error as Error).toString(), ModalTypes.ALERT);
      })
      .finally(() => setPublishConfirmationModalOpen(false));
  };

  if (jobDetail.result == null)
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <Card>
          <CardContent>
            <Typography variant="h5">Allocation job missing a result</Typography>
            <Typography variant="body1" color="text.secondary">
              This could be because the allocation job is not complete, or something has gone
              wrong.
            </Typography>
          </CardContent>
          <CardActions>
            <Button variant="contained" onClick={() => navigate(-1)}>
              Go back
            </Button>
          </CardActions>
        </Card>
      </Box>
    );

  const allocatedStudents = jobDetail.result.studentAllocations
    .filter((sa) => sa.metrics.allocated)
    .map((sa) => ({
      ...sa,
      // for the most performant way to do this but seems acceptable
      subjects: batch.studentChoices.find((sc) => sc.crsid === sa.student)?.subjects,
      teachingUnits: sa.teachingUnits.filter((tu) => tu.type === 'PRACTICAL'),
      permittedClashCount: sa.metrics.permittedClashCount,
      minimumPermittedClashCount: sa.metrics.minimumPermittedClashCount
    }));

  const studentsWithIncompatibleChoices = jobDetail.result.studentAllocations
    .filter((sa) => sa.metrics.allocationOptionCount === 0)
    .map((sa) => ({
      ...sa,
      subjects: batch.studentChoices.find((sc) => sc.crsid === sa.student)?.subjects
    }));

  return (
    <div className="job-by-id">
      <PublishConfirmationModal
        open={publishConfirmationModalOpen}
        handleConfirmation={handlePublishConfirmation}
        handleClose={() => setPublishConfirmationModalOpen(false)}
        batchCreatedAt={batch.createdAt}
        timetableName={batch.timetable.name}
        choiceCollectionName={batch.studentChoicesCollection.name}
      />
      <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
        <h2>
          Allocation: {batch.name}-{jobDetail.id}
        </h2>
        <Stack direction="row">
          <Box mr={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setPublishConfirmationModalOpen(true)}
            >
              Publish
            </Button>
          </Box>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={loadingZip}
            onClick={() => downloadZip()}
            sx={{ height: 'fit-content' }}
          >
            Download Zip
          </LoadingButton>
        </Stack>
      </Stack>
      <Stack spacing={2} sx={{ alignItems: 'flex-start' }}>
        <Grid container sx={{ gap: 2, justifyContent: 'flex-start' }}>
          <Grid item>
            <AllocationMetric
              value={batch.metrics.studentCount}
              label="Total"
              iconColor={theme.palette.info.main}
              Icon={AddCircle as typeof Icon}
            />
          </Grid>
          <Grid item>
            <AllocationMetric
              value={jobDetail.result.metrics.allocatedStudentCount}
              label="Allocated"
              iconColor={theme.palette.success.light}
              Icon={CheckCircle as typeof Icon}
            />
          </Grid>
          <Grid item>
            <AllocationMetric
              value={jobDetail.result.metrics.studentWithIncompatibleChoicesCount}
              label="Incompatible choices count"
              iconColor={theme.palette.error.main}
              Icon={Cancel as typeof Icon}
            />
          </Grid>
          <Grid item>
            <AllocationMetric
              value={jobDetail.result.metrics.overAllocationSummed}
              label="Over allocation summed"
              iconColor={theme.palette.warning.main}
              Icon={ErrorIcon as typeof Icon}
            />
          </Grid>
          <Grid item>
            <AllocationMetric
              value={jobDetail.result.metrics.overAllocatedPracticalCount}
              label="Over allocated practical count"
              iconColor={theme.palette.warning.main}
              Icon={ErrorIcon as typeof Icon}
            />
          </Grid>
          <Grid item>
            <AllocationMetric
              value={jobDetail.result.metrics.minimumPermittedClashCount}
              label="Minimum number of clashes"
              iconColor={theme.palette.secondary.main}
              Icon={HourglassDisabled as typeof Icon}
            />
          </Grid>
          <Grid item>
            <AllocationMetric
              value={jobDetail.result.metrics.permittedClashCount}
              label="Total number of clashes"
              iconColor={theme.palette.secondary.main}
              Icon={HourglassDisabled as typeof Icon}
            />
          </Grid>
          {Object.entries(jobDetail.result.metrics.permittedClashesCountGrouped).map(
            ([clash_count, count]) => (
              <Grid item key={clash_count}>
                <AllocationMetric
                  value={count}
                  label={`Allocated with ${clash_count} clash${clash_count !== '1' ? 'es' : ''}`}
                  iconColor={theme.palette.primary.light}
                  Icon={HourglassDisabled as typeof Icon}
                />
              </Grid>
            )
          )}
          <Grid item>
            <AllocationMetric
              value={batch.metrics.studentTakingChemistryPhysicsCount}
              label="Chem/Phys students"
              iconColor={theme.palette.primary.main}
              Icon={ScienceOutlined as typeof Icon}
            />
          </Grid>
          <Grid item>
            <AllocationMetric
              value={jobDetail.result.metrics.chemistryPhysicsWeeklyAlternationCount}
              label="Chem/Phys Weekly Alternation Count"
              iconColor={theme.palette.primary.dark}
              Icon={ScienceOutlined as typeof Icon}
            />
          </Grid>
          <Grid item>
            <AllocationMetric
              value={jobDetail.result.metrics.chemistryPhysicsPerfectAlternationCount}
              label="Chem/Phys Perfect Alternation Count"
              iconColor={theme.palette.primary.dark}
              Icon={ScienceOutlined as typeof Icon}
            />
          </Grid>
          <Grid item>
            <AllocationMetric
              value={subjectsResponse?.metrics.unevenlyDistributionScore}
              label="Uneven Distribution Score"
              iconColor={theme.palette.success.main}
              Icon={PieChart as typeof Icon}
            />
          </Grid>
        </Grid>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            width: '100%'
          }}
        >
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={(e, value) => setTab(value)} aria-label="lab API tabs example">
                <Tab
                  label={`Allocated Students (${jobDetail.result.metrics.allocatedStudentCount})`}
                  value="allocated"
                />
                <Tab
                  label={`Student With Incompatible Choices (${jobDetail.result.metrics.studentWithIncompatibleChoicesCount})`}
                  value="incompatible"
                />
                <Tab label="Practicals" value="practicals" />
              </TabList>
            </Box>
            <TabPanelWithoutPadding value="allocated">
              <AllocationTable rows={allocatedStudents} />
            </TabPanelWithoutPadding>
            <TabPanelWithoutPadding value="incompatible">
              <IncompatibleChoicesTable rows={studentsWithIncompatibleChoices} />
            </TabPanelWithoutPadding>
            <TabPanelWithoutPadding value="practicals">
              {subjectsResponse?.subjects.map((subject) => (
                <Box key={subject.code}>
                  <h4>{subject.name}</h4>
                  {subject.practicals.length === 0 ? (
                    <span>No practicals allocated.</span>
                  ) : (
                    <PracticalsTable loading={loadingSubjects} rows={subject.practicals} />
                  )}
                </Box>
              ))}
            </TabPanelWithoutPadding>
          </TabContext>
        </Stack>
        <AllocatorUIModal />
      </Stack>
    </div>
  );
};

export default JobById;
