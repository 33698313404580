import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { get, remove, StudentChoicesResponse } from '../api';
import { modalStore, ModalTypes } from '../AllocatorUIModal';
import StudentChoicesTable from './StudentChoicesTable';

export const StudentChoices = () => {
  const { setModal } = modalStore();
  const [students, setStudents] = useState<StudentChoicesResponse[]>([]);
  const [loadingStudents, setLoadingStudents] = useState(false);

  const fetchStudentChoices = useCallback(async () => {
    setLoadingStudents(true);
    try {
      const students = await get<StudentChoicesResponse[]>(
        `/api/v1alpha1/studentchoicescollection/active/students/`
      );
      setStudents(students);
    } catch (error) {
      setModal(`Failed to fetch student choices`, ModalTypes.ALERT);
    } finally {
      setLoadingStudents(false);
    }
  }, [setModal, setStudents]);

  useEffect(() => {
    fetchStudentChoices();
  }, [fetchStudentChoices]);

  const handleDelete = async (crsid: string) => {
    try {
      await remove(`/api/v1alpha1/studentchoices/${crsid}/`, {});
      fetchStudentChoices();
    } catch (error) {
      console.error('Error occurred while deleting student choices:', error);
    }
  };

  return (
    <Stack className="student-choices" direction="column" spacing={2}>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <h2>Student Choices</h2>
        <Link to="add">
          <Button variant="contained" startIcon={<AddIcon />}>
            Add Student Choices
          </Button>
        </Link>
      </Stack>
      <StudentChoicesTable students={students} loading={loadingStudents} onDelete={handleDelete} />
    </Stack>
  );
};

export default StudentChoices;
